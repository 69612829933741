export function calculatePaginations({
  perPage,
  currentPage,
  totalData,
  show = 3,
} = {}) {
  let length = Math.floor(totalData / perPage)
  let pages = Array.from({ length: show }, (_, i) => (i - Math.floor(show / 2)) + currentPage)
    .filter((i) => i > 0 && i < (totalData / perPage))

  if (pages.length > 0 && pages[0] !== 1) {
    pages = [1, '...', ...pages]
  }
  if (pages.length > 0 && pages[pages.length - 1] !== length) {
    pages.push('...', length)
  }

  return pages
}